export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')];

			export const server_loads = [];

			export const dictionary = {
	"/": [2],
	"/components": [3],
	"/d/[code]": [4],
	"/entity": [5],
	"/game": [6],
	"/kpis": [7],
	"/playground": [8],
	"/privacy-policy": [9],
	"/reset-password/[secret]": [10],
	"/stats": [11],
	"/stripe/[sessionId]": [12],
	"/user-journeys": [13],
	"/user-journeys/[query]": [14]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error) }),
			};